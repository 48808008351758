/* eslint-disable */
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  colors,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Link,
  makeStyles,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelActions from "@material-ui/core/ExpansionPanelActions";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import arrayMove from "array-move";
import clsx from "clsx";
import { useStoreActions, useStoreState } from "easy-peasy";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";

import SortableList from "../components/sortableList";
import LearningModuleService from "../data/api/learningModuleService";
import ImageResource from "../data/imageResource";
import ContentDAO from "../data/models/content/ContentDAO";
import KeywordActionDAO from "../data/models/content/KeywordActionDAO";
import { grey } from "@material-ui/core/colors";
const { red } = colors;
import errorNotify from "./responseNotifyHelper";

const disableStyle = {
  color: grey[700],
  marginRight: "auto"
}
const enableStyle = {
  color: red[700],
  marginRight: "auto"
}
const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: 10,
    width: "90%"
  },
  header: {
    padding: 5
  },
  subheader: {
    padding: 5
  },
  heading: {
    fontSize: theme.typography.pxToRem(14)
  },
  subheading: {
    fontSize: theme.typography.pxToRem(12)
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  },
  icon: {
    verticalAlign: "bottom",
    height: 20,
    width: 20
  },
  details: {
    alignItems: "center"
  },
  column: {
    flexBasis: "33.33%"
  },
  keywordsColumn: {
    flexBasis: "66.66%",
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5)
    }
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2)
  },
  keywordActionsText: {
    padding: theme.spacing(1, 2)
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline"
    }
  },
  container: {
    width: "60%"
  },
  customInput: {
    fontSize: theme.typography.pxToRem(15)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  },
  langaugeCode: {
    width: "25%"
  },
  addRiskArea: {
    margin: 5,
    color: "#FFCC00",
    background: "black"
  }
}));

const pageDescriptionList = [
  { value: "Learn" },
  { value: "LearnDetailPage" },
  { value: "CommonQuestions" },
  { value: "FilterQuestionsPage" },
  { value: "TPIScreen" },
  { value: "PreApproval" },
  { value: "CTOT" },
  { value: "AskAQuestion" },
  { value: "SubmittedScreen" },
  { value: "MyContacts" }
];

const PageDescriptionArray = [
  "Learn",
  "LearnDetailPage",
  "CommonQuestions",
  "FilterQuestionsPage",
  "TPIScreen",
  "PreApproval",
  "CTOT",
  "AskAQuestion",
  "SubmittedScreen",
  "MyContacts"
];

const moduleImages = [
  {
    value: "TestIconKey",
    image: ImageResource.get("TestIconKey")
  },
  {
    value: "cardImage",
    image: ImageResource.get("cardImage")
  },
  {
    value: "AntiBribery_BriberyBusinessCourtesies",
    image: ImageResource.get("AntiBribery_BriberyBusinessCourtesies")
  },
  {
    value: "AntiBribery_HighSalesPrice",
    image: ImageResource.get("AntiBribery_HighSalesPrice")
  },
  {
    value: "AntiBribery_IntroToAntiBribery",
    image: ImageResource.get("AntiBribery_IntroToAntiBribery")
  },
  {
    value: "AntiBribery_LocalCharities",
    image: ImageResource.get("AntiBribery_LocalCharities")
  },
  {
    value: "AntiBribery_SalesAgent",
    image: ImageResource.get("AntiBribery_SalesAgent")
  },
  {
    value: "AntiBribery_SubDealers",
    image: ImageResource.get("AntiBribery_SubDealers")
  },
  {
    value: "AntiBribery_ThirdPartyIntermediaries",
    image: ImageResource.get("AntiBribery_ThirdPartyIntermediaries")
  },
  {
    value: "AntiBribery_VisaInvitationLetters",
    image: ImageResource.get("AntiBribery_VisaInvitationLetters")
  },
  {
    value: "DataPrivacy_AccidentalBreach",
    image: ImageResource.get("DataPrivacy_AccidentalBreach")
  },
  {
    value: "DataPrivacy_BreachResponse",
    image: ImageResource.get("DataPrivacy_BreachResponse")
  },
  {
    value: "DataPrivacy_Header",
    image: ImageResource.get("DataPrivacy_Header")
  },
  {
    value: "DataPrivacy_PersonalInformation",
    image: ImageResource.get("DataPrivacy_PersonalInformation")
  },
  {
    value: "DataPrivacy_PrivacyByDesign",
    image: ImageResource.get("DataPrivacy_PrivacyByDesign")
  },
  {
    value: "DataPrivacy_ThirdParties",
    image: ImageResource.get("DataPrivacy_ThirdParties")
  },
  {
    value: "DataPrivacy_TradeShows",
    image: ImageResource.get("DataPrivacy_TradeShows")
  },
  {
    value: "DataPrivacy_Websites",
    image: ImageResource.get("DataPrivacy_Websites")
  },
  {
    value: "FairCompetition_CompetitiveInformation1",
    image: ImageResource.get("FairCompetition_CompetitiveInformation1")
  },
  {
    value: "FairCompetition_CompetitorSupplier",
    image: ImageResource.get("FairCompetition_CompetitorSupplier")
  },
  {
    value: "FairCompetition_Header",
    image: ImageResource.get("FairCompetition_Header")
  },
  {
    value: "FairCompetition_StatisticalInformationExchanges",
    image: ImageResource.get("FairCompetition_StatisticalInformationExchanges")
  },
  {
    value: "FairCompetition_TradeAssociations",
    image: ImageResource.get("FairCompetition_TradeAssociations")
  },
  {
    value: "FairCompetition_TradeShows",
    image: ImageResource.get("FairCompetition_TradeShows")
  },
  {
    value: "FairCompetition_TrainingWithCompetitors",
    image: ImageResource.get("FairCompetition_TrainingWithCompetitors")
  },
  {
    value: "TradeCompliance_AntiBoycottCompliance",
    image: ImageResource.get("TradeCompliance_AntiBoycottCompliance")
  },
  {
    value: "TradeCompliance_Customs",
    image: ImageResource.get("TradeCompliance_Customs")
  },
  {
    value: "TradeCompliance_Diversion",
    image: ImageResource.get("TradeCompliance_Diversion")
  },
  {
    value: "TradeCompliance_ExportControls",
    image: ImageResource.get("TradeCompliance_ExportControls")
  },
  {
    value: "TradeCompliance_HandCarry",
    image: ImageResource.get("TradeCompliance_HandCarry")
  },
  {
    value: "TradeCompliance_Licensing",
    image: ImageResource.get("TradeCompliance_Licensing")
  },
  {
    value: "TradeCompliance_RedFlags",
    image: ImageResource.get("TradeCompliance_RedFlags")
  },
  {
    value: "TradeCompliance_Tours",
    image: ImageResource.get("TradeCompliance_Tours")
  },
  {
    value: "ConflictofInterestPhoto",
    image: ImageResource.get("ConflictofInterestPhoto")
  },
  {
    value: "InsiderTradingPhoto",
    image: ImageResource.get("InsiderTradingPhoto")
  },
  {
    value: "ConflictofInterestPersonalGain",
    image: ImageResource.get("ConflictofInterestPersonalGain")
  },
  {
    value: "ConflictofInterestSupplier",
    image: ImageResource.get("ConflictofInterestSupplier")
  },
  {
    value: "ConflictofInterestAdvise",
    image: ImageResource.get("ConflictofInterestAdvise")
  },
  {
    value: "ConflictofInterestLunch",
    image: ImageResource.get("ConflictofInterestLunch")
  },
  {
    value: "ConflictofInterestGifts",
    image: ImageResource.get("ConflictofInterestGifts")
  },
  {
    value: "ConflictofInterestSports",
    image: ImageResource.get("ConflictofInterestSports")
  },
  {
    value: "ConflictofInterestOutsideOrganization",
    image: ImageResource.get("ConflictofInterestOutsideOrganization")
  },
  {
    value: "ConflictofInterestNepotism",
    image: ImageResource.get("ConflictofInterestNepotism")
  },
  {
    value: "ConflictofInterestExamples",
    image: ImageResource.get("ConflictofInterestExamples")
  },
  {
    value: "InsiderTradingTipping",
    image: ImageResource.get("InsiderTradingTipping")
  },
  {
    value: "InsiderTradingFriend",
    image: ImageResource.get("InsiderTradingFriend")
  },
  {
    value: "InsiderTradingSupplier",
    image: ImageResource.get("InsiderTradingSupplier")
  },
  {
    value: "InsiderTradingOverhear",
    image: ImageResource.get("InsiderTradingOverhear")
  },
  {
    value: "InsiderTradingOpen",
    image: ImageResource.get("InsiderTradingOpen")
  },
  {
    value: "InsiderTradingPolicy",
    image: ImageResource.get("InsiderTradingPolicy")
  },
  {
    value: "InsiderTradingMNPI",
    image: ImageResource.get("InsiderTradingMNPI")
  }
];

const riskAreaIcons = [
  {
    value: "antiBriberyIcon",
    Component: ImageResource.getIconComponent("antiBriberyIcon")
  },
  {
    value: "dataPrivacyIcon",
    Component: ImageResource.getIconComponent("dataPrivacyIcon")
  },
  {
    value: "fairCompetitionIcon",
    Component: ImageResource.getIconComponent("fairCompetitionIcon")
  },
  {
    value: "tradeComplianceIcon",
    Component: ImageResource.getIconComponent("tradeComplianceIcon")
  },
  {
    value: "conflictOfInterestIcon",
    Component: ImageResource.getIconComponent("conflictOfInterestIcon")
  },
  {
    value: "insiderTradingIcon",
    Component: ImageResource.getIconComponent("insiderTradingIcon")
  }
];

const languages = {
  zh: "Chinese (Simplified)",
  nl: "Dutch",
  en: "English",
  fr: "French",
  de: "German",
  it: "Italian",
  ja: "Japanese",
  pt: "Portuguese",
  ru: "Russian",
  es: "Spanish"
};

let flag = "disabled";
const groupLearningModules = (learningModules, scenarioModules) => {
  const groupObject = {};
  const groupedObjectArray = [];
  learningModules.forEach(learningModule => {
    const { riskArea } = learningModule;
    const _learningModuleObject = groupObject[riskArea] || { data: [] };
    _learningModuleObject.data.push({
      id: learningModule.getContentData().getLevelOrder(),
      value: new ContentDAO().toObject(learningModule)
    });
    groupObject[riskArea] = _learningModuleObject;
  });
  scenarioModules.forEach(learningModule => {
    const { riskArea } = learningModule;
    const _learningModuleObject = groupObject[riskArea] || { data: [] };
    _learningModuleObject.data.push({
      id: learningModule.getContentData().getLevelOrder(),
      value: new ContentDAO().toObject(learningModule)
    });
    groupObject[riskArea] = _learningModuleObject;
  });
  for (const key in groupObject) {
    const dataClone = [...groupObject[key].data];
    groupObject[key].data = dataClone.sort((a, b) => a.id - b.id);
    groupedObjectArray.push({ ...groupObject[key], key });
  }
  return groupedObjectArray;
};

const LearningModulesContainer = () => {
  const classes = useStyles();
  const [learningModulesGroup, setLearningModulesGroup] = useState([]);
  const [scenarioModulesGroup, setScenarioModulesGroup] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [newRiskArea, setNewRiskArea] = useState(new ContentDAO());
  // eslint-disable-next-line
  const fetchModulesThunk = useStoreActions(actions => actions.modules.fetchData);
  const fetchRiskAreasThunk = useStoreActions(actions => actions.modules.fetchRiskAreas);
  const learningModules = useStoreState(state => state.modules.learningModules);
  const scenarioModules = useStoreState(state => state.modules.scenarioModules);
  const riskAreas = useStoreState(state => state.modules.riskAreas);
  const modulesStoreStatus = useStoreState(state => state.modules.status);
  const setModulesStoreState = useStoreActions(actions => actions.modules.setStatus);
  const updateLanguage = useStoreActions(actions => actions.language.changeLanguage);
  const [languageCode, setCurrentLanguageCode] = useState(
    useStoreState(state => state.language.language)
  );
  const fetchModules = useCallback(async (lang) => {
    await fetchModulesThunk(lang || 'en');
  }, [fetchModulesThunk]);

  const fetchRiskAreas = useCallback(async (lang) => {
    await fetchRiskAreasThunk(lang || 'en');
  }, [fetchRiskAreasThunk])

  const fetchLMItems = useCallback(async (lang) => {
    const language = lang || 'en';
    await Promise.all([fetchRiskAreasThunk(language), fetchModules(language)]);
  }, [fetchRiskAreasThunk, fetchModules]);

  useEffect(() => {
    fetchLMItems(languageCode);
  }, [fetchLMItems, languageCode])
  const addRiskAreaThunk = useStoreActions(
    actions => actions.modules.updateLearningModule
  );

  useEffect(() => {
    const _learningModulesGroup = groupLearningModules(learningModules, scenarioModules);
    setLearningModulesGroup(_learningModulesGroup);
    setScenarioModulesGroup(_learningModulesGroup);

    const groupObject = {};

    riskAreas.forEach((riskArea) => {
      const ra = riskArea.getRiskArea();
      if (!_learningModulesGroup.some(e => e.key === ra)) {
        const _learningModuleObject = groupObject[ra] || { data: [] };
        _learningModuleObject.data.push({
          id: '1234',
          value: riskArea
        });
        groupObject[ra] = _learningModuleObject;
      }
    });
    for (const key in groupObject) {
      const dataClone = [...groupObject[key].data];
      groupObject[key].data = dataClone.sort((a, b) => a.id - b.id);
      _learningModulesGroup.push({ ...groupObject[key], key });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [learningModules, scenarioModules, riskAreas]);

  const handleAddRiskArea = () => {
    const initObject = {
      riskArea: "",
      languageCode: languageCode,
      contentType: "Description",
      riskAreaIconKey: "",
      contentData: {
        description: ""
      }
    };

    const newRiskAreaItem = new ContentDAO().toObject(initObject);

    setNewRiskArea(newRiskAreaItem);

    setModalVisible(true);
  };

  const handleEditRiskArea = (ra) => {
    const riskArea = riskAreas.filter(x => x.riskArea === ra);
    const learningtreeObject = learningModulesGroup.filter(x => x.key === ra);
    flag = learningtreeObject[0].data.length > 0 && learningtreeObject[0].data[0].id !== "1234" ? "disabled" : "enabled";
    setNewRiskArea(new ContentDAO(riskArea[0]));
    setModalVisible(true);
  };

  const handleClose = () => {
    setModalVisible(false);
  };

  const handleSubmit = useCallback(async () => {
    setModalVisible(false);
    try {
      await addRiskAreaThunk(newRiskArea);
    } catch (error) {
      errorNotify(error);
    }
    await fetchRiskAreas();
  }, [newRiskArea, addRiskAreaThunk, fetchRiskAreas]);

  const handleDelete = useCallback(async () => {
    setModalVisible(false);
    try {
      await LearningModuleService.deleteRiskArea(newRiskArea);
      await fetchRiskAreas();
    } catch (error) {
      errorNotify(error);
    }
  }, [newRiskArea, fetchRiskAreas]);

  const validateForm = () => {
    if (validateString(newRiskArea.getRiskArea()) && newRiskArea.getRiskAreaIconKey()) {
      return false;
    } else if (!validateString(newRiskArea.getRiskArea()) && newRiskArea.getRiskAreaIconKey()) {
      return true;
    } else {
      return false;
    }
  };

  const updateNewRiskArea = useCallback(() => {
    const updatedRiskArea = new ContentDAO().toObject(newRiskArea);
    setNewRiskArea(updatedRiskArea);
  }, [newRiskArea]);

  const updateLanguageCode = useCallback(async (lang) => {
    updateLanguage(lang);
    await fetchRiskAreas(lang);
    await fetchModules(lang);
  }, [updateLanguage, fetchRiskAreas, fetchModules]);

  // eslint-disable-next-line react/display-name
  const RenderLearningModulesGroup = React.memo(props => {
    return props.group.map(({ data, key }, index) => {
      return (
        <Fragment key={index + key}>
          <Grid container justify="space-between">
            <Typography variant="h6" inline="true" align="left" noWrap className={classes.subheader}>
              {key}
            </Typography>
            <Typography color="primary" inline="true" align="right">
              <Link href="#" onClick={() => handleEditRiskArea(key)}>
                Edit Risk Area
              </Link>
            </Typography>
          </Grid>
          <Divider />
          <LearningModules
            submodules={data}
            riskAreas={riskAreas}
            test={scenarioModulesGroup}
            riskArea={key}
            refetchModules={fetchModules}
            refetchRiskAreas={fetchRiskAreas}
            setLoading={setModulesStoreState}
            languageCode={languageCode}
          ></LearningModules>
        </Fragment>
      );
    });
  });

  return (
    <div className={classes.root}>
      <Backdrop className={classes.backdrop} open={modulesStoreStatus === "pending"}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: 15 }}>
        <Fab
          variant="extended"
          aria-label="publish"
          className={classes.addRiskArea}
          onClick={handleAddRiskArea}
        >
          Add Risk Area
        </Fab>
      </div>
      <Typography variant="h4" noWrap className={classes.header}>
        Learning Modules
      </Typography>
      <TextField
        label="Language Code"
        className={classes.langaugeCode}
        defaultValue={languageCode}
        select
        size="medium"
        onChange={({ target }) => {
          setCurrentLanguageCode(target.value);
          updateLanguageCode(target.value);
        }}
        margin="normal"
        InputLabelProps={{ shrink: true }}
        InputProps={{ className: classes.customInput }}
      >
        {Object.entries(languages).map(([key, value]) => (
          <MenuItem key={value} value={key}>
            {value}
          </MenuItem>
        ))}
      </TextField>
      <RenderLearningModulesGroup group={learningModulesGroup} />
      <Dialog
        open={modalVisible}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={"md"}
      >
        <DialogTitle id="alert-dialog-title">Add Risk Area</DialogTitle>
        <DialogContent>
          <FormGroup>
            <Grid container spacing={2}>
              <Grid container>
                <Grid container>
                  <Grid item xs={2}>
                    <Box ml={2}>
                      <TextField
                        label="Risk Area Icon"
                        className={classes.textField}
                        defaultValue={newRiskArea.getRiskAreaIconKey()}
                        select
                        onChange={({ target }) => {
                          newRiskArea.setRiskAreaIconKey(target.value);
                        }}
                        onBlur={updateNewRiskArea}
                        margin="normal"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ className: classes.customInput }}
                        fullWidth
                      >
                        {riskAreaIcons.map(icon => {
                          const { value, Component } = icon;
                          return (
                            <MenuItem key={value} value={value}>
                              <Component
                                width={50}
                                height={50}
                                style={{ backgroundColor: "#ffffff" }}
                              ></Component>
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={4}>
                    <Box ml={2}>
                      <TextField
                        label="Risk Area"
                        className={classes.textField}
                        defaultValue={newRiskArea.getRiskArea()}
                        onChange={({ target }) => {
                          newRiskArea.setRiskArea(target.value);
                        }}
                        onBlur={updateNewRiskArea}
                        margin="normal"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ className: classes.customInput }}
                        fullWidth
                      ></TextField>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={4}>
                    <Box ml={2}>
                      <TextField
                        margin="normal"
                        label="Description"
                        fullwidth="true"
                        multiline
                        onChange={({ target }) => {
                          newRiskArea.getContentData().setDescription(target.value);
                        }}
                        onBlur={updateNewRiskArea}
                        defaultValue={
                          newRiskArea.getContentData() ? newRiskArea.getContentData().getDescription() : ''
                        }
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </FormGroup>
          <DialogActions>
            <Button
              style={(flag === "disabled" && newRiskArea.identifier !== undefined) ? disableStyle : (newRiskArea.identifier === undefined || flag === "enabled") ? enableStyle : {}}
              size="small"
              onClick={handleDelete}
              disabled={(flag === "disabled" && newRiskArea.identifier !== undefined) ? true : false}
            >
              Delete
            </Button>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSubmit} color="primary" disabled={!validateForm()}>
              Submit
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
};

// To validate any string should not contains any special character
const validateString = str => {
  return /[`#!@$%^&*()+\=\[\]{};':"\\|,.<>\/?~]/.test(str);
};

const LearningModules = ({ submodules, test, riskArea, riskAreas, refetchModules, modalVisible, languageCode }) => {
  const classes = useStyles();
  const [learningModules, setLearningModules] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [activeLearningModule, setActiveLearningModule] = useState(new ContentDAO());
  const [activeLearningModuleIndex, setActiveLearningModuleIndex] = useState(null);
  const [scenarioModules, setScenarioModules] = useState([]);
  const [activeScenarioModule, setActiveScenarioModule] = useState(new ContentDAO());
  const [activeScenarioModuleIndex, setActiveScenarioModuleIndex] = useState(null);
  const [keywordModalVisible, setKeywordModalVisible] = useState(false);
  const [currentKeywordContent, setCurrentKeywordContent] = useState({
    index: -1,
    data: new KeywordActionDAO()
  });
  const [linkType, setLinkType] = useState(null);
  const updateLearningModuleThunk = useStoreActions(
    actions => actions.modules.updateLearningModule
  );
  const moduleDescriptionList = useStoreState(state => state.modules.moduleDescriptionList);
  const moduleDescriptionArray = useStoreState(state => state.modules.moduleDescriptionArray);

  const resetActiveLearningModule = useCallback(() => {
    const initObject = {
      riskArea: "",
      languageCode: languageCode,
      contentType: "Modules",
      contentData: {
        category: "",
        question: "",
        answer: ""
      },
      keywordActions: []
    };
    const initLearningModule = new ContentDAO().toObject(initObject);
    setActiveLearningModule(initLearningModule);
    setActiveLearningModuleIndex(null);
    setLinkType(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetActiveScenarioModule = useCallback(() => {
    const initObject = {
      riskArea: "",
      languageCode: languageCode,
      contentType: "Modules",
      contentData: {
        category: "",
        question: "",
        answer: ""
      },
      keywordActions: []
    };
    const initLearningModule = new ContentDAO().toObject(initObject);
    setActiveScenarioModule(initLearningModule);
    setActiveScenarioModuleIndex(null);
    setLinkType(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateActiveLearningModule = useCallback(() => {
    const updatedLearningModule = new ContentDAO().toObject(activeLearningModule);
    setActiveLearningModule(updatedLearningModule);
  }, [activeLearningModule]);

  const updateActiveScenarioModule = useCallback(() => {
    const updatedLearningModule = new ContentDAO().toObject(activeScenarioModule);
    setActiveScenarioModule(updatedLearningModule);
  }, [activeScenarioModule]);

  const handleSubmitModule = useCallback(async () => {
    setIsLoading(true);
    try {
      await updateLearningModuleThunk(activeLearningModule);
    }
    catch (error) {
      errorNotify(error);
    }
    await refetchModules();
    setIsLoading(false);
    await refetchModules();
  }, [activeLearningModule, refetchModules, updateLearningModuleThunk]);

  const handleSubmitScenario = useCallback(async () => {
    setIsLoading(true);
    try {
      await updateLearningModuleThunk(activeScenarioModule);
    }
    catch (error) {
      errorNotify(error);
    }
    setIsLoading(false);
    await refetchModules();
  }, [activeScenarioModule, refetchModules, updateLearningModuleThunk]);

  useEffect(() => {
    const learning = [];
    const scenario = [];
    submodules.forEach((module) => {
      if (module.value.getContentType() === 'Modules') {
        if (module.value.getContentData().getLevel() === 'Scenario') {
          scenario.push(module);
          return;
        }

        learning.push(module);
      }
    });
    setLearningModules(learning);
    setScenarioModules(scenario);
    resetActiveLearningModule();
    resetActiveScenarioModule();
  }, [resetActiveLearningModule, submodules, test, resetActiveScenarioModule, modalVisible]);


  const handleExpansionSelect = ({ data, index }) => {
    if (index === activeLearningModuleIndex) {
      setActiveLearningModuleIndex(null);
      return;
    }
    const initActiveLearningModule = new ContentDAO().toObject(data);
    setActiveLearningModuleIndex(index);
    setActiveLearningModule(initActiveLearningModule);
  };

  const handleExpansionSelectScenario = ({ data, index }) => {
    if (index === activeScenarioModuleIndex) {
      setActiveScenarioModuleIndex(null);
      return;
    }
    const initActiveLearningModule = new ContentDAO().toObject(data);
    setActiveScenarioModuleIndex(index);
    setActiveScenarioModule(initActiveLearningModule);
  };

  const handleAddModule = () => {
    const initObject = {
      riskArea: riskArea,
      languageCode: languageCode,
      contentType: "Modules",
      riskAreaIconKey: "SomeKey",
      contentData: {
        iconKey: "",
        title: "",
        level: "Learning Module",
        levelOrder: ""
      }
    };
    const newModuleIndex = learningModules.length;
    const newLearningModule = new ContentDAO().toObject(initObject);
    const learningModulesClone = [...learningModules];

    newLearningModule.getContentData().setLevelOrder(newModuleIndex);
    learningModulesClone.push({ id: newModuleIndex, value: newLearningModule });
    setLearningModules(learningModulesClone);
    setActiveLearningModule(newLearningModule);
    setActiveLearningModuleIndex(newModuleIndex);
  };

  const handleAddScenario = () => {
    const initObject = {
      riskArea: riskArea,
      languageCode: languageCode,
      contentType: "Modules",
      riskAreaIconKey: "SomeKey",
      contentData: {
        iconKey: "",
        title: "",
        level: "Scenario",
        levelOrder: ""
      }
    };
    const newModuleIndex = scenarioModules.length;
    const newLearningModule = new ContentDAO().toObject(initObject);
    const learningModulesClone = [...scenarioModules];

    newLearningModule.getContentData().setLevelOrder(newModuleIndex);
    learningModulesClone.push({ id: newModuleIndex, value: newLearningModule });
    setScenarioModules(learningModulesClone);
    setActiveScenarioModule(newLearningModule);
    setActiveScenarioModuleIndex(newModuleIndex);
  };

  const handleCancelModule = () => {
    const learningModulesClone = [...learningModules];
    if (!activeLearningModule.getIdentifier()) {
      learningModulesClone.splice(activeLearningModuleIndex, 1);
      setLearningModules(learningModulesClone);
      resetActiveLearningModule();
      return;
    }
    resetActiveLearningModule();
  };

  const handleCancelScenario = () => {
    const learningModulesClone = [...scenarioModules];
    if (!activeScenarioModule.getIdentifier()) {
      learningModulesClone.splice(activeScenarioModuleIndex, 1);
      setScenarioModules(learningModulesClone);
      resetActiveScenarioModule();
      return;
    }
    resetActiveScenarioModule();
  };

  const handleRemoveModule = async () => {
    setIsLoading(true);
    try {
      const learningModulesClone = [...learningModules];

      if (!activeLearningModule.getIdentifier()) {
        learningModulesClone.splice(activeLearningModuleIndex, 1);
        setLearningModules(learningModulesClone);
        resetActiveLearningModule();
        return;
      }
      const promiseArray = [];
      learningModulesClone.splice(activeLearningModuleIndex, 1);
      let learningModulesCloneArr = learningModulesClone.map((module, index) => {
        if (index !== module.value.getContentData().getLevelOrder()) {
          module.value.getContentData().setLevelOrder(index);
          promiseArray.push(LearningModuleService.updateLearningModule(module.value));
        }
        module.value.getContentData().setLevelOrder(index);
        return {
          id: index,
          value: module.value
        };
      });
      await Promise.all(promiseArray);
      await LearningModuleService.deleteLearningModule(activeLearningModule);
    } catch (error) {
      errorNotify(error);
    }
    await refetchModules();
    setIsLoading(false);
  };

  const handleRemoveScenario = async () => {
    setIsLoading(true);
    try {
      const learningModulesClone = [...scenarioModules];

      if (!activeScenarioModule.getIdentifier()) {
        learningModulesClone.splice(activeScenarioModuleIndex, 1);
        setScenarioModules(learningModulesClone);
        resetActiveScenarioModule();
        return;
      }
      const promiseArray = [];
      learningModulesClone.splice(activeScenarioModuleIndex, 1);
      let learningModulesCloneArrayData = learningModulesClone.map((module, index) => {
        if (index !== module.value.getContentData().getLevelOrder()) {
          module.value.getContentData().setLevelOrder(index);
          promiseArray.push(LearningModuleService.updateLearningModule(module.value));
        }
        module.value.getContentData().setLevelOrder(index);
        return {
          id: index,
          value: module.value
        };
      });
      await Promise.all(promiseArray);
      await LearningModuleService.deleteLearningModule(activeScenarioModule);
      await refetchModules();
    } catch (error) {
      errorNotify(error);
    }
    setIsLoading(false);
  };

  const handleRemoveKeyword = index => {
    const keywordActionsClone = [...activeLearningModule.getKeywordActions()];
    keywordActionsClone.splice(index, 1);
    activeLearningModule.setKeywordActions(keywordActionsClone);
    updateActiveLearningModule();
  };
  const updateCurrentKeywordContent = () => {
    const { index, data } = currentKeywordContent;
    const updatedKeywordContent = new KeywordActionDAO(data);
    setCurrentKeywordContent({ index, data: updatedKeywordContent });
  };

  const handleKeywordContentSubmit = () => {
    const { index, data } = currentKeywordContent;
    const _keywordActions = [...activeLearningModule.getKeywordActions()];
    const keywordContentObject = new KeywordActionDAO().toObject(data);
    if (index === -1 && data.getAction() && data.getKeyword()) {
      _keywordActions.push(keywordContentObject);
      activeLearningModule.setKeywordActions(_keywordActions);
    } else {
      _keywordActions[index] = keywordContentObject;
      activeLearningModule.setKeywordActions(_keywordActions);
    }
    updateActiveLearningModule();
    setKeywordModalVisible(false);
    setCurrentKeywordContent({
      index: -1,
      data: new KeywordActionDAO()
    });
    setLinkType(null);
  };

  const handleSortSubmodules = async ({ oldIndex, newIndex }) => {
    setIsLoading(true);
    try {
      const promiseArray = [];
      const sortedLearningModules = arrayMove(learningModules, oldIndex, newIndex).map(
        ({ value }, index) => {
          if (index !== value.getContentData().getLevelOrder()) {
            value.getContentData().setLevelOrder(index);
            promiseArray.push(LearningModuleService.updateLearningModule(value));
          }
          value.getContentData().setLevelOrder(index);
          return { id: index, value };
        }
      );
      await Promise.all(promiseArray);
      setLearningModules(sortedLearningModules);
    } catch (error) {
      errorNotify(error);
    }
    setIsLoading(false);
  };

  const handleSortScenarios = async ({ oldIndex, newIndex }) => {
    setIsLoading(true);
    try {
      const promiseArray = [];
      const sortedLearningModules = arrayMove(scenarioModules, oldIndex, newIndex).map(
        ({ value }, index) => {
          if (index !== value.getContentData().getLevelOrder()) {
            value.getContentData().setLevelOrder(index);
            promiseArray.push(LearningModuleService.updateLearningModule(value));
          }
          value.getContentData().setLevelOrder(index);
          return { id: index, value };
        }
      );
      await Promise.all(promiseArray);
      setScenarioModules(sortedLearningModules);
    } catch (error) {
      errorNotify(error);
    }
    setIsLoading(false);
  };

  const SortableExpansionsContainer = SortableContainer(({ children }) => {
    return (
      <Box component="span" m={4}>
        {children}
      </Box>
    );
  });

  const SortableScenarioSubmoduleElement = SortableElement(content => {
    const { value, someIndex } = content;
    const index = someIndex;
    const learningModule = value;
    return (
      <ExpansionPanel
        expanded={activeScenarioModuleIndex === index}
        key={learningModule.identifier}
        onChange={() => handleExpansionSelectScenario({ data: learningModule, index })}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1c-content"
          id="panel1c-header"
        >
          <div className={classes.column}>
            <Typography className={classes.heading}>
              {learningModule.getContentData().getTitle()}
            </Typography>
          </div>
        </ExpansionPanelSummary>
        <Divider variant="middle" />
        <ExpansionPanelDetails className={classes.details}>
          <Grid container spacing={2}>
            <Grid container>
              <Grid container>
                <Grid item xs={2}>
                  <Box ml={2}>
                    <TextField
                      key={`activeLearningModuleIconKey` + learningModule.identifier}
                      id={`activeLearningModuleIconKeyID` + learningModule.identifier}
                      label="IconKey"
                      className={classes.textField}
                      defaultValue={activeScenarioModule.getContentData().getIconKey()}
                      select
                      onChange={({ target }) => {
                        activeScenarioModule.getContentData().setIconKey(target.value);
                        updateActiveScenarioModule();
                      }}
                      margin="normal"
                      InputLabelProps={{ shrink: true }}
                      InputProps={{ className: classes.customInput }}
                      fullWidth
                    >
                      {moduleImages.map(icon => {
                        const { value, image } = icon;
                        return (
                          <MenuItem key={value} value={value}>
                            <img src={image} alt="not found" width={100} height={100}></img>
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </Box>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={4}>
                  <Box ml={2}>
                    <TextField
                      key={`activeLearningModuleRiskArea` + learningModule.identifier}
                      id={`activeLearningModuleRiskAreaID` + learningModule.identifier}
                      label="Risk Area"
                      className={classes.textField}
                      defaultValue={activeScenarioModule.getRiskArea()}
                      onChange={({ target }) => {
                        activeScenarioModule.setRiskArea(target.value);
                      }}
                      onBlur={updateActiveScenarioModule}
                      margin="normal"
                      InputLabelProps={{ shrink: true }}
                      InputProps={{ className: classes.customInput, readOnly: true }}
                      fullWidth
                    ></TextField>
                  </Box>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={4}>
                  <Box ml={2}>
                    <TextField
                      key={`activeLearningModuleTitle` + learningModule.identifier}
                      id={`activeLearningModuleTitleID` + learningModule.identifier}
                      label="Title"
                      className={classes.textField}
                      defaultValue={activeScenarioModule.getContentData().getTitle()}
                      onChange={({ target }) => {
                        activeScenarioModule.getContentData().setTitle(target.value);
                      }}
                      onBlur={updateActiveScenarioModule}
                      margin="normal"
                      InputLabelProps={{ shrink: true }}
                      InputProps={{ className: classes.customInput }}
                      fullWidth
                    ></TextField>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <div className={clsx(classes.column, classes.keywordActionsText)}>
              <Typography variant="subtitle2">Keyword Actions</Typography>
            </div>
            <div className={classes.keywordsColumn}>
              {activeScenarioModule.getKeywordActions().map((keywordAction, index) => {
                return (
                  <Chip
                    key={keywordAction.keyword}
                    label={
                      keywordAction.keyword.length >= 33
                        ? keywordAction.keyword.slice(0, 30) + "..."
                        : keywordAction.keyword.slice(0, 30)
                    }
                    onClick={() => {
                      setCurrentKeywordContent({ index, data: keywordAction });
                      const action = keywordAction.getAction();
                      const description = keywordAction.getDescription();
                      const pageDescriptionSet = new Set(PageDescriptionArray);
                      const moduleDescriptionSet = new Set(moduleDescriptionArray);
                      if (action === "Link") {
                        if (pageDescriptionSet.has(description)) setLinkType("page");
                        if (moduleDescriptionSet.has(description)) setLinkType("module");
                      }
                      setKeywordModalVisible(true);
                    }}
                    onDelete={() => handleRemoveKeyword(index)}
                  />
                );
              })}
              <Chip
                label="Add Keyword"
                color="primary"
                onClick={() => {
                  setCurrentKeywordContent({
                    index: -1,
                    data: new KeywordActionDAO()
                  });
                  setLinkType(null);
                  setKeywordModalVisible(true);
                }}
              ></Chip>
            </div>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <div className={clsx(classes.column, classes.keywordActionsText)}>
              <Typography variant="subtitle2">Sections</Typography>
            </div>
            <div className={classes.keywordsColumn}>
              <SortableList
                getListItems={() => activeScenarioModule.getContentData().getSections()}
                setListItems={sectionList => {
                  activeScenarioModule.getContentData().setSections(sectionList);
                  const activeScenarioModuleClone = new ContentDAO().toObject(activeScenarioModule);
                  setActiveScenarioModule(activeScenarioModuleClone);
                }}
                title="Section List"
              ></SortableList>
            </div>
          </Grid>
        </ExpansionPanelDetails>
        <Divider />
        <ExpansionPanelActions>
          <Button
            style={{ color: red[700], marginRight: "auto" }}
            size="small"
            onClick={handleRemoveScenario}
          >
            Delete
          </Button>
          <Button size="small" onClick={handleCancelScenario}>
            Cancel
          </Button>
          <Button size="small" color="primary" onClick={handleSubmitScenario}>
            Save
          </Button>
        </ExpansionPanelActions>
      </ExpansionPanel>
    );
  });

  const SortableSubmoduleElement = SortableElement(content => {
    const { value, someIndex } = content;
    const index = someIndex;
    const learningModule = value;
    return (
      <ExpansionPanel
        expanded={activeLearningModuleIndex === index}
        key={learningModule.identifier}
        onChange={() => handleExpansionSelect({ data: learningModule, index })}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1c-content"
          id="panel1c-header"
        >
          <div className={classes.column}>
            <Typography className={classes.heading}>
              {learningModule.getContentData().getTitle()}
            </Typography>
          </div>
        </ExpansionPanelSummary>
        <Divider variant="middle" />
        <ExpansionPanelDetails className={classes.details}>
          <Grid container spacing={2}>
            <Grid container>
              <Grid container>
                <Grid item xs={2}>
                  <Box ml={2}>
                    <TextField
                      key={`activeLearningModuleIconKey` + learningModule.identifier}
                      id={`activeLearningModuleIconKeyID` + learningModule.identifier}
                      label="IconKey"
                      className={classes.textField}
                      defaultValue={activeLearningModule.getContentData().getIconKey()}
                      select
                      onChange={({ target }) => {
                        activeLearningModule.getContentData().setIconKey(target.value);
                        updateActiveLearningModule();
                      }}
                      margin="normal"
                      InputLabelProps={{ shrink: true }}
                      InputProps={{ className: classes.customInput }}
                      fullWidth
                    >
                      {moduleImages.map(icon => {
                        const { value, image } = icon;
                        return (
                          <MenuItem key={value} value={value}>
                            <img src={image} alt="not found" width={100} height={100}></img>
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </Box>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={4}>
                  <Box ml={2}>
                    <TextField
                      key={`activeLearningModuleRiskArea` + learningModule.identifier}
                      id={`activeLearningModuleRiskAreaID` + learningModule.identifier}
                      label="Risk Area"
                      className={classes.textField}
                      defaultValue={activeLearningModule.getRiskArea()}
                      onChange={({ target }) => {
                        activeLearningModule.setRiskArea(target.value);
                      }}
                      onBlur={updateActiveLearningModule}
                      margin="normal"
                      InputLabelProps={{ shrink: true }}
                      InputProps={{ className: classes.customInput, readOnly: true }}
                      fullWidth
                    ></TextField>
                  </Box>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={4}>
                  <Box ml={2}>
                    <TextField
                      key={`activeLearningModuleTitle` + learningModule.identifier}
                      id={`activeLearningModuleTitleID` + learningModule.identifier}
                      label="Title"
                      className={classes.textField}
                      defaultValue={activeLearningModule.getContentData().getTitle()}
                      onChange={({ target }) => {
                        activeLearningModule.getContentData().setTitle(target.value);
                      }}
                      onBlur={updateActiveLearningModule}
                      margin="normal"
                      InputLabelProps={{ shrink: true }}
                      InputProps={{ className: classes.customInput }}
                      fullWidth
                    ></TextField>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <div className={clsx(classes.column, classes.keywordActionsText)}>
              <Typography variant="subtitle2">Keyword Actions</Typography>
            </div>
            <div className={classes.keywordsColumn}>
              {activeLearningModule.getKeywordActions().map((keywordAction, index) => {
                return (
                  <Chip
                    key={keywordAction.keyword}
                    label={
                      keywordAction.keyword.length >= 33
                        ? keywordAction.keyword.slice(0, 30) + "..."
                        : keywordAction.keyword.slice(0, 30)
                    }
                    onClick={() => {
                      setCurrentKeywordContent({ index, data: keywordAction });
                      const action = keywordAction.getAction();
                      const description = keywordAction.getDescription();
                      const pageDescriptionSet = new Set(PageDescriptionArray);
                      const moduleDescriptionSet = new Set(moduleDescriptionArray);
                      if (action === "Link") {
                        if (pageDescriptionSet.has(description)) setLinkType("page");
                        if (moduleDescriptionSet.has(description)) setLinkType("module");
                      }
                      setKeywordModalVisible(true);
                    }}
                    onDelete={() => handleRemoveKeyword(index)}
                  />
                );
              })}
              <Chip
                label="Add Keyword"
                color="primary"
                onClick={() => {
                  setCurrentKeywordContent({
                    index: -1,
                    data: new KeywordActionDAO()
                  });
                  setLinkType(null);
                  setKeywordModalVisible(true);
                }}
              ></Chip>
            </div>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <div className={clsx(classes.column, classes.keywordActionsText)}>
              <Typography variant="subtitle2">Sections</Typography>
            </div>
            <div className={classes.keywordsColumn}>
              <SortableList
                getListItems={() => activeLearningModule.getContentData().getSections()}
                setListItems={sectionList => {
                  activeLearningModule.getContentData().setSections(sectionList);
                  const activeLearningModuleClone = new ContentDAO().toObject(activeLearningModule);
                  setActiveLearningModule(activeLearningModuleClone);
                }}
                title="Section List"
              ></SortableList>
            </div>
          </Grid>
        </ExpansionPanelDetails>
        <Divider />
        <ExpansionPanelActions>
          <Button
            style={{ color: red[700], marginRight: "auto" }}
            size="small"
            onClick={handleRemoveModule}
          >
            Delete
          </Button>
          <Button size="small" onClick={handleCancelModule}>
            Cancel
          </Button>
          <Button size="small" color="primary" onClick={handleSubmitModule}>
            Save
          </Button>
        </ExpansionPanelActions>
      </ExpansionPanel>
    );
  });

  return (
    <div className={classes.root}>
      <SortableExpansionsContainer distance={1} axis="y" onSortEnd={handleSortSubmodules}>
        {learningModules.map(({ value }, index) => {
          if (value.getContentType() !== 'Description')
            return (
              <SortableSubmoduleElement
                key={`item-${value.identifier}-container`}
                collection="submodules"
                index={index}
                value={value}
                someIndex={index}
              ></SortableSubmoduleElement>
            )
        })}
        <ExpansionPanel color="primary" expanded={false} onClick={handleAddModule}>
          <ExpansionPanelSummary
            expandIcon={<AddIcon color="primary" />}
            aria-controls="panel1c-content"
            id="panel1c-header"
          >
            <div className={classes.column}>
              <Typography className={classes.subheading} color="primary">
                Add Learning Module
              </Typography>
            </div>
          </ExpansionPanelSummary>
        </ExpansionPanel>
      </SortableExpansionsContainer>
      <SortableExpansionsContainer distance={1} axis="y" onSortEnd={handleSortScenarios}>
        {scenarioModules.map(({ value }, index) => {
          if (value.getContentType() != 'Description')
            return (
              <SortableScenarioSubmoduleElement
                key={`item-${value.identifier}-container`}
                collection="submodules"
                index={index}
                value={value}
                someIndex={index}
              ></SortableScenarioSubmoduleElement>
            )
        })}
        <ExpansionPanel color="primary" expanded={false} onClick={handleAddScenario}>
          <ExpansionPanelSummary
            expandIcon={<AddIcon color="primary" />}
            aria-controls="panel1c-content"
            id="panel1c-header"
          >
            <div className={classes.column}>
              <Typography className={classes.subheading} color="primary">
                Add Scenario Module
              </Typography>
            </div>
          </ExpansionPanelSummary>
        </ExpansionPanel>
      </SortableExpansionsContainer>
      <Dialog
        open={keywordModalVisible}
        onClose={() => {
          setLinkType(null);
          setKeywordModalVisible(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-title">Edit Keyword Content</DialogTitle>
        <DialogContent>
          <FormGroup className={classes.container} noValidate autoComplete="off">
            <TextField
              key="activeKeywordContent"
              id="keywordActionContent-LearningModules"
              label="Keyword"
              className={classes.textField}
              defaultValue=""
              value={currentKeywordContent.data.getKeyword()}
              onChange={event => {
                const { value } = event.target;
                currentKeywordContent.data.setKeyword(value);
                updateCurrentKeywordContent();
              }}
              margin="normal"
            ></TextField>
            <TextField
              key="activeKeywordAction"
              id="keywordActionAction-LearningModules"
              label="Action"
              select
              defaultValue=""
              className={classes.textField}
              value={currentKeywordContent.data.getAction()}
              onChange={event => {
                const { value } = event.target;
                currentKeywordContent.data.setAction(value);
                updateCurrentKeywordContent();
              }}
              margin="normal"
            >
              {[{ value: "Modal" }, { value: "Link" }, { value: "External Link" }].map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.value}
                </MenuItem>
              ))}
            </TextField>
            {currentKeywordContent.data.getAction() === "Link" ? (
              // Code Start: THIS IS for the ADD LEARNING Module
              <Fragment>
                <FormControl component="fieldset" className={classes.formControl}>
                  <RadioGroup
                    aria-label="linkType"
                    name="linkType1"
                    value={linkType}
                    onChange={event => {
                      setLinkType(event.target.value);
                      currentKeywordContent.data.setDescription(null);
                      updateCurrentKeywordContent();
                    }}
                  >
                    <FormControlLabel
                      label="Page"
                      value="page"
                      control={<Radio checked={linkType === "page"} style={{ color: "#FFCC00" }} />}
                    />
                    <FormControlLabel
                      label="Module"
                      value="module"
                      control={
                        <Radio checked={linkType === "module"} style={{ color: "#FFCC00" }} />
                      }
                    />
                  </RadioGroup>
                </FormControl>
                {linkType === "page" ? (
                  <TextField
                    label="Description"
                    key="activeKeywordPageDescription"
                    id="keywordActionPageDescription-submoduleContent"
                    select
                    defaultValue=""
                    className={classes.textField}
                    value={currentKeywordContent.data.getDescription()}
                    onChange={event => {
                      const { value } = event.target;
                      currentKeywordContent.data.setDescription(value);
                      currentKeywordContent.data.setOptions("page");
                      updateCurrentKeywordContent();
                    }}
                    margin="normal"
                  >
                    {pageDescriptionList.map(option => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.value}
                      </MenuItem>
                    ))}
                  </TextField>
                ) : (
                  <TextField
                    label="Description"
                    key="activeKeywordModuleDescription"
                    id="keywordActionModuleDescription-submoduleContent"
                    select
                    defaultValue=""
                    className={classes.textField}
                    value={currentKeywordContent.data.getDescription()}
                    onChange={event => {
                      const { value } = event.target;
                      currentKeywordContent.data.setDescription(value);
                      currentKeywordContent.data.setOptions("module");
                      updateCurrentKeywordContent();
                    }}
                    margin="normal"
                  >
                    {moduleDescriptionList.map(option => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              </Fragment>
            ) : (
              // Code Start: THIS code for the ADD Scenarios Module
              <TextField
                label="Description"
                key="activeKeywordDescription"
                id="keywordActionDescription-submoduleContent"
                multiline
                className={classes.textField}
                value={currentKeywordContent.data.getDescription()}
                onChange={event => {
                  const { value } = event.target;
                  currentKeywordContent.data.setDescription(value);
                  updateCurrentKeywordContent();
                }}
                margin="normal"
              ></TextField>
            )}
          </FormGroup>
          <DialogActions>
            <Button onClick={() => setKeywordModalVisible(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={handleKeywordContentSubmit} color="primary">
              Submit
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default LearningModulesContainer;
